<template>
    <Form @submit="handleSubmit" v-slot="{ errors }">
        <div class="modal" v-if="modelValue" :class="{ active : modelValue }">
            <div class="modal_container">
                <div class="modal_header border-bottom">
                    <h1>Mindbody Integration</h1>
                    <button class="close_btn left_out" @click="closeModal()"><i class="fas fa-long-arrow-alt-right"></i></button>
                </div>
                <div class="modal_body">
                    <div class="setting_wpr mt-3 text-center" v-if="user.mb_id">
                        <h3>Connected</h3>
                        <i>Your clients will be synced every night.</i>
                        <br /><br />
                    </div>
                    <div class="setting_wpr" v-else>
                        <div class="form_grp">
                            <div class="group_item">
                                <label class="input_label">Studio ID:</label>
                                <div class="field_wpr" :class="{ 'has-error': errors.studio_id }">
                                    <Field autocomplete="off" name="studio_id" type="text" v-model="form.mb_studio_id" placeholder="ex: 9999" rules="required" label="studio id" />
                                </div>
                                <ErrorMessage name="studio_id" class="text-danger" />
                            </div>
                        </div>
                    </div>
                    <div class="integration_info">
                        <img src="@/assets/images/mind-body.jpg" alt="" class="mind_body" v-if="!user.mb_id">
                        <p class="mt-3">
                            Mindbody charges a monthly fee of $12.95 to sync your contacts. This $12.95 monthly add-on fee will be billed at the same interval as your current billing.
                            <br /><br />
                            Example: if you are Basic Annual, your add-on fee to use sync will be billed for 12 months at $12.95 per month upfront.
                        </p>
                    </div>
                    <div v-if="user.mb_id" class="mt-3 action_wpr flex-center">
                        <button type="button" :disabled="loader" @click="handleMBSync" class="btn save_btn">{{ loader ? 'Syncing' : 'Sync Clients Now' }}</button>
                    </div>
                </div>
                <div class="modal_footer">
                    <button :disabled="loader" type="button" class="btn cancel_btn" @click="closeModal()">Cancel</button>
                    <button :disabled="loader" v-if="!user.mb_id" class="btn save_btn"><i class="fa fa-spin fa-spinner" v-if="loader"></i> {{ loader ? 'Connecting' : 'Connect' }}</button>
                </div>
            </div>
        </div>
    </Form>
</template>

<script>
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState, mapActions } from 'vuex'

    import axios from '@/services/axios'
    import Toastr from '@/utils/Toastr'
    import Helper from '@/utils/Helper'
    import Swal from 'sweetalert2'

    export default {
        name: 'Mindbody Integration',

        data () {
            return {
                form: {
                    mb_studio_id: '',
                },
                loader: false,
                billingApi: process.env.VUE_APP_BILLING_API_URL,
            };
        },

        props: {
            modelValue: Boolean,
        },

        emits: ['update:modelValue'],

        components: {
            Field,
            Form,
            ErrorMessage,
        },

        watch: {
            user: {
                handler () {
                    const vm = this;

                    vm.resetForm();
                },
                deep: true
            },

            modelValue(val) {
                if (val) {
                    const vm = this;
                    vm.form.mb_studio_id = '';

                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            }
        },

        computed: mapState({
            user: state => state.authModule.user,
            plansAndAddons: state => state.billingModule.plans,
            subscription: state => state.billingModule.subscription,
        }),

        mounted () {
            const vm = this;

            vm.resetForm();
        },

        methods: {
            ...mapActions({
                syncMBClient: 'contactModule/syncMBClient',
                integrateMBSync: 'contactModule/integrateMBSync',
                refreshAuth: 'authModule/refreshAuth',
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            resetForm () {
                const vm = this;

                vm.form = {
                    mb_studio_id: '',
                };
            },

            handleSubmit () {
                const vm = this;

                vm.loader = true;

                if (vm.subscription.has_mindbody || !vm.user.stripe_id) {
                    vm.integrateMBSync({ studio_id: vm.form.mb_studio_id }).then((resp) => {
                        vm.loader = false;
                        vm.closeModal();
                    }).catch((err) => {
                        vm.loader = false;
                        Toastr.handleServerError(err);
                    });
                } else {
                    vm.billingConfirmation();
                }
            },

            handleMBSync () {
                const vm = this;
                const options = Helper.swalConfirmOptions('', 'Would you like to merge the existing records from your MindBody contacts?');
                options.cancelButtonText = 'No';

                vm.loader = true;

                Swal.fire(options).then((result) => {
                    vm.syncMBClient({ is_merge: result.isConfirmed }).then((resp) => {
                        if (resp.data.status == 1) {
                            const options = Helper.swalWarningOptions('Success!', 'Your request has been queued. Importing process may take up to 5-10 mins.');
                            Swal.fire(options);
                        }

                        vm.closeModal();
                        vm.loader = false;
                    }).catch((err) => {
                        vm.loader = false;
                        Toastr.handleServerError(err);
                    });
                });
            },

            billingConfirmation () {
                const vm = this;
                const addonPlan = vm.plansAndAddons.addons.month.filter((plan) => plan.nickname == 'mindbody')[0];
                const options = Helper.swalConfirmOptions('Are you sure?', `$${Math.round((parseFloat(addonPlan.amount) + Number.EPSILON) * 100) / 100} will be charged now and will be added to your next billing. Would you like to proceed?`, 'PROCEED');
                options.preConfirm = function () {
                                          return new Promise(function(resolve, reject) {
                                              axios.post(`${vm.billingApi}/stripe/subscription/increment-quantity`, { plan: addonPlan.price_id }, {
                                                  headers: {
                                                      Accept: 'application/json',
                                                      Authorization: `Bearer ${vm.user.access_token}`,
                                                  },
                                              }).then((resp) => {
                                                  vm.refreshAuth();

                                                  vm.integrateMBSync({ studio_id: vm.form.mb_studio_id }).then((resp) => {
                                                      vm.loader = false;
                                                      vm.closeModal();
                                                  }).catch((err) => {
                                                      vm.loader = false;
                                                      Toastr.handleServerError(err);
                                                  });

                                                  resolve(true);
                                              }).catch((err) => {
                                                  Toastr.error(err.response.data.error);
                                                  resolve(true);
                                              });
                                          });
                                      }

                Swal.fire(options).then((result) => {
                    if (result.isDismissed) {
                        vm.loader = false;
                    }
                });
            },
        },
    }
</script>

<style scoped>
    .integration_info{
        padding: 15px 20px 30px 20px;
        background: #fff;
        border-radius: 8px;
    }
    .integration_info img{
        max-height: 80px;
        width: auto;
    }
    .integration_info p {
        font-size: 15px;
        line-height: 23px;
        color: #5a5a5a;
        font-weight: 400;
    }
</style>
